import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchHotels(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('hotel', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchHotel(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`hotel/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addHotel(ctx, hotelData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('hotel', hotelData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateHotel(ctx, hotelData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`hotel/${hotelData.id}`, hotelData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
  },
};
